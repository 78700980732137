<template>
  <div>
    <div class="box box--sm box--gray radius-bottom-0 mt-16 d-flex">
      <multiple-select :value="filterValues" :options="options" />

      <div class="d-flex mr-16">
        <search-box v-model="searchText" holder-text="Search by Email" />

        <button class="btn btn--md btn--primary mr-8">Search</button>

        <selection-box v-model="params.perPage" :options="pagerOptions" :is-align-right="true" />
      </div>
    </div>

    <div class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0">
      <table class="table table--striped">
        <thead>
          <tr>
            <th>Order No.</th>
            <th>Email</th>
            <th>Withdraw Gold<br />Amount (gram)</th>
            <th>Delivery Address</th>
            <th>Withdraw Time<br />(UTC)</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          <router-link
            tag="tr"
            :to="{ name: 'GoldInvestmentWithdraw', params: { id: item } }"
            v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            :key="item"
            class="link--clone"
          >
            <td>1234567{{ item }}</td>
            <td class="clipboard-copy wr-15">
              <span class="clipboard-copy__text">
                <span class="clipboard-copy__text-inner">dinhhaildh9@gmail.com</span>
                <copy-clipboard :is-external="false" tooltip-class="custom" @on-copy="doCopy($event)" />
              </span>
            </td>
            <td>{{ (item * 0.1) | float_number_with_commas }}</td>
            <td class="wr-25">Psr Melawai Bl A Los O Bl CCT/62 RT 003/02, Dki Jakarta,12160</td>
            <td>9:39 AM<br />3/29/2020 UTC</td>
            <td>PENDING</td>
          </router-link>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { PAGER_OPTIONS, PAYMENT_ORDER_STATUS_OPTIONS } from '@/utils/data-sets'
import pagination from '@/mixins/pagination'
import copyClipboard from '@/mixins/copy-clipboard'
const MultipleSelect = () => import('@/components/MultipleSelect')
const SearchBox = () => import('@/components/SearchBox')
const SelectionBox = () => import('@/components/SelectionBox')
const CopyClipboard = () => import('@/components/CopyClipboard')

export default {
  data() {
    return {
      options: [
        {
          label: 'Order By',
          options: [
            { name: 'Most Recent', value: 'updated_at', orderBy: 'desc', category: 'order' },
            { name: 'Highest Withdraw Gold Amount', value: 'amount_currency', orderBy: 'desc', category: 'order' },
            { name: 'Lowest Withdraw Gold Amount', value: 'amount_currency', orderBy: 'asc', category: 'order' }
          ]
        },
        {
          label: 'Status Filter',
          options: PAYMENT_ORDER_STATUS_OPTIONS
        }
      ],
      filterValues: [],
      searchText: '',
      pagerOptions: PAGER_OPTIONS,
      params: {
        perPage: 10
      }
    }
  },

  mixins: [pagination, copyClipboard],

  components: {
    SearchBox,
    MultipleSelect,
    SelectionBox,
    CopyClipboard
  },

  methods: {}
}
</script>
